body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  /*-moz-osx-font-smoothing: grayscale;*/
  /*background: #588ea3;*/
}
/*div {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}*/

/*Full_size_start*/
/*loginModal*/
.modal_background{
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(50, 50, 50, 1);
  z-index: 10000;
}
.modal_box{
  position: absolute;
  top: 50vh ; left: 40vw;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 20vw;
}
/*loginModal_end*/

/*progressModal*/
.progress_modal_background{
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: #00000044;
  z-index: 10000;
}
.progress_modal_box{
  position: absolute;
  top: 50vh ; left: 40vw;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: whitesmoke;
  width: 20vw;
}
/*loginModal_end*/

/*header*/
.header {
  /*background: rgba(30, 200, 200, 1);*/
  height: 10vh;
  border-bottom: 1px solid black;
}
.header .logo {
  padding-top: 5px;
  font-size: 30px;
  font-weight: bold;
  color: black;
}

/*header_end*/
/*menu_start*/

.menu ul li a {
  color: black;
  font-weight: bold;
}
.dropdown a {
  color: black;
  font-weight: bold;
}

.active{
  font-size: 18px;
  font-weight: bold;
  color: black
}
/*menu_end*/
/*accordion_start*/
.accordion-item {
  border: none;
}
.accordion-collapse collapse show {
  color: black;
}
/*accordion_end*/
/*excel_start*/
.excel_body {
  overflow-y: scroll;
  height: 71vh;
}
/*excel_end*/
/*member_start*/
.member_body {
  font-size: 14px;
  overflow-y: scroll;
  height: 75vh;
}
/*member_end*/
/*business_start*/
.business_body {
  overflow-y: scroll;
  height: 75vh;
}
/*business_end*/
/*memberList_start*/
.memberList th {
  font-size: 13px;
}
.memberList td {
  font-size: 13px;
}
/*memberList_end*/
/*member_upload_start*/
.member_table th {
  font-size: 14px;
}
.member_table td {
  font-size: 14px;
}
/*member_upload_end*/
/*pagination_start*/
.pagination button[aria-current] {
  background: #6c757d;
  color: white;
}
/*pagination_end*/
/*Full_size_end*/




/*size1280_start*/

/*size1280_end*/
/*bg_color_start*/
.bgWait {
  background-color: #FFC10777;
}
.bgComplete {
  background-color: #0D6EF277;
}
.bgCancel {
  background-color: #DC354577;
}
/*bg_color_end*/
